.CSPrincContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-top: 1px solid rgb(241, 238, 238); 
}
.headerRow{
    height: 4rem;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 700;   
    padding:1rem 0; 
    color: red;
    background-color: white;
    padding: 20px;
    font-family: Sparkasse_Bd;
}
/*CARD CONFIGURATION*/
.cardConfiguration_Container,
.eCommerceP_Container{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}
.cardConfiguration_Title,
.eCommerceP_Title{
    height: 1.5rem;
    display: flex;
    align-items: center;
    background-color: #f9f9f9 ;
    padding-left: 1rem;
    font-size: 13px;
    color: #999;
    font-family: 'Sparkasse_Rg';
    margin-bottom: 1px;
}
/*CARD CONFIGURATION --> SUSPEND THE CARD*/
.cardConfiguration_SuspendTheCard_Container,
.cardConfiguration_SelectGeographicArea_Container,
.eCommerceP_Suspend_Container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding:1.5rem;
    background-color: white;
    margin-bottom: 5px;
    width: 100%;
}

.cardConfiguration_SuspendTheCard_Text_Container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.cardConfiguration_SuspendTheCard_SubTitle{
    width: 100%;
    display: flex;
    text-align: left;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 700;
    font-family: Sparkasse_Bd;
}
.cardConfiguration_SuspendTheCard_StatusLabel_Container{
    width: 100%;
    display: flex;
    margin-top: 0.5rem;
}
.cardConfiguration_SuspendTheCard_Button_Container,
.cardConfiguration_SelectGeographicArea_Button_Container,
.eCommerceP_Suspend_Button_Container{
    min-width: 17rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/*CARD CONFIGURATION --> SELECT GEOGRAPHIC AREA*/

.cardConfiguration_SelectGeographicArea_Text_Container,
.eCommerceP_Text_Container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.cardConfiguration_SelectGeographicArea_Title{
    width: 100%;
    display: flex;
    text-align: left;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 700;
    font-family: Sparkasse_Bd;
}
.cardConfiguration_SelectGeographicArea_SubTitle{
    width: 100%;
    display: flex;
    text-align: left;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;
    text-align: left;
    font-family: Sparkasse_Rg;
}
.cardConfiguration_SelectGeographicArea_CheckBox_Conteiner{
    display: flex;
    height: 3rem;
    width: 100%;
    align-items: center;
    gap:70px
}

/*CARD CONFIGURATION --> CHANGE PIN*/
.cardConfiguration_ChangeCardPin_Container,
.eCommerceP_ChangeEPin_Container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    padding: 20px;
    width: 100%;
}
/* .cardConfiguration_ChangeCardPin_Item{
    height: 60%;
    width: 20%;
    min-width: 15.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    color: gray;
    font-weight: 600;
    cursor: pointer;
} */


.eCommerceP_SubTitle{
    width: 100%;
    display: flex;
    text-align: left;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 700;
    font-family: Sparkasse_Bd;
}
.eCommerceP_StatusLabel_Container{
    width: 100%;
    display: flex;
}

.eCommerceP_ChangeEPin_Item,
.cardConfiguration_ChangeCardPin_Item{
    min-width: 13rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    color: gray;
    font-weight: 600;
    cursor: pointer;
    font-family: Sparkasse_Bd;
}

/*Change pin view*/
.changePin_Princ_Container{
    width: 100%;
    min-height:calc(100vh - 13rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid rgb(241, 238, 238); 
    padding: 0;
}
/*  HEADER  */
.changePin_headerRow{
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content:space-between;
    align-items: center;
}
.changePin_headerRow_Title{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    /* font-weight: 700;   */
    background-color:white ;
    margin: .5rem 0;
    padding:1rem ;
    color: red;
    font-size: 23px;
    letter-spacing: 0.32px;
    line-height: 29px;
    font-family: Sparkasse_Bd;
}
.changePin_headerRow_History{
    padding-top: .5rem;
    font-size: 1rem;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: rgba(123, 129, 134, 0.651);
    background-color: white;
    color: #666666;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    padding: 5px;
    padding-right: 1rem;
    font-family: Sparkasse_Rg;
}
.changePin_Navigability{
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 0.4rem ;
    text-decoration: none;
}
.changePin_Navigability_right{
    font-weight:700; 
    margin-left:0.4rem;
    color:'gray';
    font-family: Sparkasse_Bd;
}

.changePin_Navigability:hover{
    border-bottom: 1px solid gray;
}
.CS_AfterCall_Spinner{
    display: flex;
    align-items: center;
    position: fixed;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99;
    background-color: #6161619c;
}
.cs_secondondarySpinner_Continer{
    width: 100%;
    padding: 0 30rem;
  }
@media screen and (min-width: 1401px) and (max-width: 1600px)  {
    .cs_secondondarySpinner_Continer{
        padding: 0 20rem;
      }
}
@media screen and (min-width: 1221px) and (max-width: 1400px)  {
    .cs_secondondarySpinner_Continer{
        padding: 0 15rem;
      }
}
@media screen and (min-width: 1001px) and (max-width: 1220px)  {
    .cs_secondondarySpinner_Continer{
        padding: 0 10rem;
      }
}
@media screen and (max-width: 1000px)  {
    .cardConfiguration_SuspendTheCard_Container, 
    .cardConfiguration_SelectGeographicArea_Container, 
    .eCommerceP_Suspend_Container{
        flex-direction: column;
        align-items:flex-start;
        width: 100%;
    }
    .cs_secondondarySpinner_Continer{
        padding: 0 5rem;
      }
}

@media screen and (max-width: 767px){
    .cardConfiguration_SuspendTheCard_Button_Container,
    .cardConfiguration_SelectGeographicArea_Button_Container,
    .eCommerceP_Suspend_Button_Container{
        min-width: 100%;
    }
    .CSPrincContainer{
        padding: 0;
    }
    .cardConfiguration_Container,
    .eCommerceP_Container{
        border-right: none;
        border-left: none;
    }
    .buttonItem{
        line-height: .9
    }
    .headerRow{
        padding-left: 1rem;
    }
    .cs_secondondarySpinner_Continer{
        padding: 0 5rem;
      }
}
@media screen and (max-width: 766px){
    .cs_secondondarySpinner_Continer{
        padding: 0;
      }
}