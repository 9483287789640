.iframeWindow,
.iframeContainer{
    border: none;
    height:  357px;
    width: 100%;
}

@media screen and (max-width: 884px)  {
    .iframeWindow,
    .iframeContainer{
        height: 325px;
    }
}
@media screen and (max-width: 784px){
    .iframeWindow,
    .iframeContainer{
        height: 245px;
    }
}