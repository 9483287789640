
@font-face {
    font-family: 'cardFont';
    src: local('cardFont'), url(../../../asset/font/creditCard/OCRAStd.otf) format('opentype');
  }

.HCPrincContainer{
    width: 100%;
    border-top: 1px solid #FF6262;
}

.homeCardHeader{
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
}
/*  CARD    */
.cardContainerView{
    width: 100%;
    min-height: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FF0000;
}
.cardTitle{
    font-size: 1.5rem;
    color: white;
    margin: 1rem;
    font-family: Sparkasse_Bd;
}
.bankCardContainer{
    width: 31rem;
    height: 18rem;
    border-radius: 1rem;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.452);
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}

.bankCardContainer.flipped{
    cursor:default;
}

.bankCardItem {
    height: 100%;
    width: 100%;
    font-family: 'cardFont';
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2rem
}



.bankCardItemHeader{
    width: 100%;
}

.cardContentContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.cardContentLabelContainer{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;

}
.cardSingleLabelContainer{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 2rem;
}
.cardSingleLabelItem{
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    font-size: 1.2rem;
    justify-content: space-between;
    width: 100%;
}

.cardContentCircuitLogoContainer{
    display: flex;
    justify-content: space-between;
}
.cardContentCircuitLogoItem{
    min-width: 5.25rem;
    height: 3.5rem;
    background-position-y:-1rem;
    background-size:cover;
    align-self: flex-end;
} 

.cardStatusRow{
    margin: 1rem;
    font-size: 1.5rem;
    font-family: Sparkasse_Rg;
}

.cardNumber{
   width: 100%;
   font-size: 1.5rem;
   font-weight: 600;
   margin-bottom: 0.5rem;
   color: #2D2926;
   margin-right: 2rem;
   letter-spacing:3px;
   display: flex;
}

/*  AVAIABLE ROW    */
.avaiableRow{
    height: 4.15rem;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    margin-top: .5rem;
}

.avaiableRow_lable{
    font-weight: 400;
    margin-right:2rem;
    font-family: 'Sparkasse_Rg';
    color: #444444;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 23px;
}

.avaiableRow_amount{
    font-weight: 700;
    font-family: 'Sparkasse_Bd';
    color: #444444;
    font-size: 26px;
    letter-spacing: 0;
    line-height: 29px;
}
/*TRANSACTIONS SPINNER*/
.homeCardLoaderContainer{
    display: flex;
    align-items: center;
    width: 100%;
  }

/*  EMPTY HOME TRANSACTION CONTAINER   */
.transaction_ClosePage_EmptyContainer,
.statements_ClosePage_EmptyContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1.1rem;
    font-weight: 600;
    color: rgb(168, 167, 167);
    padding: 2rem;
}

/* FULL HOME TRANSACTION (CLOSE) CONTAINER   */
.transactionClosePageContainer,
.statementsClosePageContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    margin-top: .5rem;
    
}
.transaction_ClosePage_Header{
    height: 5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: 700;
    font-family: 'Sparkasse_Bd';
    color: red;
    font-size: 22px;
    padding-left: 2rem;
    letter-spacing: 0.32px;
    line-height: 29px;
}
.transaction_ClosePage_lastTransactions_Container{
    display: flex;
    flex-direction: column;
}
.transaction_ClosePage_ButtonContainer{
    display: flex;
    height: 4rem;
    justify-content: flex-end;
    padding-right: 1rem;
}
.transaction_ClosePage_ButtonItem{
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
}
.seeAllTransactionButton{
    cursor: pointer;
    margin-right: 0.5rem;
    color: #666666;
    font-size: 14px;
    font-family: 'Sparkasse_Bd';
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
    font-weight: 700;
}

/*   COMPONENT CONTAINER   */
.transaction_Component_PageContainer{
    min-width: 55rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
}

@media screen and (max-width: 900px)  {
    .bankCardContainer{
        width: 26rem;
        height: 15rem;
    }
    .bankCardItem{
        height: auto;
        padding: 2rem 1.5rem
    }
    .cardNumber{
        font-size: 1.4rem;
        letter-spacing:1px;
    }
    .cardSingleLabelItem{
        font-size: 1.1rem;
    }
    .cardContentCircuitLogoItem{
        display: flex;
        min-width: 3.25rem;
        height: 2.5rem;
        background-size:cover;
        background-repeat: no-repeat;
        margin-left: 0;
        background-position-y:-0.5rem;
    } 
    .bankCardContainer {
        border-radius: 13px;
    }
    .bankCardItemHeader {
        margin-bottom: 1.5rem;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 800px)  {
    .bankCardContainer{
        width: 19rem;
        height: 11rem;
    }
    .bankCardItem{
        height: auto;
        padding: 4rem 1rem
    }
    .cardNumber{
        font-size: 1rem;
        letter-spacing:0px;
    }
    .cardSingleLabelItem{
        font-size: 0.8rem;
    }
    .cardContentCircuitLogoItem{
        display: flex;
        min-width: 3.25rem;
        height: 2.5rem;
        background-size:cover;
        background-repeat: no-repeat;
        margin-left: 0;
        background-position-y:-0.5rem;
    } 
    .bankCardContainer {
        border-radius: 10px;
    }
    .bankCardItemHeader {
        margin-bottom: 0.5rem;
        margin-top: 1.5rem;
    }
    .cardSingleLabelContainer {
        margin-bottom: 1rem;
    }
}

.promotionTourSection{
    position: relative;
    z-index: 0;
}

/* MOBILE */

@media screen and (max-width: 767px)  {
    .HCPrincContainer{
        border: none;
    }
    .cardTitle{
        display: none;
    }
    .cardStatusRow{
        font-size: 0.8rem
    }
    .avaiableRow{
        justify-content: space-between;
        padding:1rem;
        border-bottom:2px solid rgb(241, 238, 238);
    }
    .avaiableRow_lable{
        font-size: 0.8rem;
        margin-right: 0;
    }
    .transaction_ClosePage_Header{
        display: none;
    }
    .transaction_ClosePage_ButtonContainer{
        justify-content: flex-end;
        padding:1rem
    }
    .transaction_ClosePage_ButtonItem{
        font-size: 1rem;
    }
    .transaction_ClosePage_lastTransactions_Container{
        width: 100%;
    }
}

@media screen and (max-width: 400px)  {

    .bankCardItem{
        padding:1.5rem  1rem;
        height: 100%;
        padding-bottom: 0.5rem;
    }
    .bankCardItemHeader {
        margin-top: 0rem;
    }
    .cardContentContainer {
        height: 100%;
    }
    .cardSingleLabelContainer {
        height: 3.2rem;
    }
  
}

@media screen and (max-width: 320px) {
    .avaiableRow{
        height: auto;
        flex-direction: column;
    }
}

