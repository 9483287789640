@font-face {
  font-family: 'Sparkasse_Rg';
  src: local('Sparkasse_Rg'), url(./asset/font/Sparkasse_web_V2.04/Sparkasse_Rg.ttf) format('opentype');
}

body {
  margin: 0;
  font-family: Sparkasse_Rg, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,body{
  height: 100vh;
}

code {
  font-family: Sparkasse_Rg, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* define rem */
:root {
  font-size: 16px;
}

.link_Button{
  color: #666666 !important;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  font-family: Sparkasse_Bd;
  text-decoration: underline !important;
  background:none;
  border: none;
  cursor: pointer;
  width: auto;
}

