.serviceInformationPopup {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
}

.serviceInformationPopup .popupContent{
    display: flex;
    background-color: white;
    margin: auto;
    height: 300px;
    width: 500px;
    padding: 1rem;
    flex-direction: column;
    justify-content: start;
    align-items: baseline;
    overflow-y: hidden;
}

.serviceInformationPopup .popupContent .header{
    font-size: 1.1rem;
    font-weight: 700;
    line-height: 22px;
}

.serviceInformationPopup .popupContent .body{
    width: 100%;
    text-align: left;
    margin-top: 5px;
    overflow-y: auto;
}

.remindMeButton{
    background-color: white;
    color: red;
    font-weight: 700;
    cursor: pointer;
    border: none;
    font-size: 1rem;
    margin-top: 5px;
}

.serviceInformationPopup .popupContent .popupButtons{
    margin-top: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.serviceInformationPopup .popupContent .langButtonContainer .langButton{
    background-color: white;
    color: red;
    border: 1px solid gainsboro;
}

.serviceInformationPopup .popupContent .langButtonContainer .langButton:hover{
    background-color: red;
    color: white;
}

.serviceInformationPopup .popupContent .langButtonContainer .langTooltip{
    background-color: dimgray;
    color: white;
    z-index: 1000;
    top: 5px;
    right: -65px
}

@media screen and (max-width: 767px) {
    .serviceInformationPopup .popupContent{
        width: 300px;
    }
}