.labelContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  text-align: left;
  /* background-color: brown; */
}
.labelTitle {
  height: 100%;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 1rem;
  font-weight: 400;
  color: darkgray;
  font-family: Sparkasse_Rg;
}
.labelItem {
  height: 100%;
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 1.1rem;
  font-weight: 500;
  font-family: Sparkasse_Rg;
}

.labelItem::before{
    display:block;
    content:""
}

@media screen and (max-width: 767px) {
  .labelTitle {
    width: 50%;
  }
  .labelItem {
    width: 50%;
  }
}
@media screen and (max-width: 500px) {
  .labelItem {
    font-size: 1rem;
  }
  .labelTitle {
    width: 45%;
  }
  .labelItem {
    width: 55%;
  }
}
@media screen and (max-width: 350px) {
  .labelItem {
    font-size: 14px;
  }
  .labelTitle {
    font-size: 14px;
    width: 45%;
  }
}
