.otpContainer{
    height: 2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #999;
    text-align: center;
    font-size: 16px;
    color: #999;
    font-family: 'Sparkasse_Rg';
    cursor: pointer;
    background: none;
    padding: 0 1rem;
    background:none;
}


.otp-field {
    display: flex;
    max-width: 5rem;
    height: 100%;
    outline: none;
    padding: .5rem;
    border-width: 0;
    font-size: 1.7rem;
    text-align: center;
    line-height: normal;
}

@media screen and (max-width: 650px)  {
    .otp-field {
        margin:0;
        padding: 0;
        width: 100%;
    }
}

@media screen and (max-width: 500px)  {
    .otp-field {
        margin:0;
        padding: 0;
        font-size: 1.2rem;
        width: 100%;
    }
}

@media screen and (max-width: 350px)  {
    .otpContainer{
        font-size: 14px;
    }
}