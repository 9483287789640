.error_PageContainer{
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    color: white;
    font-size: 8rem;
    flex-direction: column;
}
.error_Background_Logo_Container{
    display: flex;
    min-width: 18rem;
    height: 3.3rem;
    background-color: white;
    z-index: 1002;
}
.error_Background_Logo_item{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y:-2.1rem ;
}