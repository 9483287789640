.statementListCard_Container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    overflow-y: auto;
}
.statementListCard_allBox_Container{
    width: 100%;
    display: flex;
    flex-direction: column;

}
.statementListCard_year_Container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.7rem;
    font-weight: 700;
    padding-top: 0.2rem;
    background-color: white;
    padding-left: 1.5rem;
    
}
.statementListCard_SingleBox_Container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 1.4rem;
    cursor: pointer;
    margin-bottom: 2px;
}
.statementListCard_SingleBox_iconAndTitle-Raw{
    width: 8rem;
    height: 2rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
}
@media screen and (max-width: 767px) {
    .statementListCard_year_Container{
        padding:0 1rem
    }
    .statementListCard_SingleBox_Container{
        border:none;
        border-bottom: 1px solid #D9D9D9;
        padding: 1rem;
    }
    .statementListCard_year_Container{
        font-size: 1.1rem;
        font-weight: normal;
    }
}