.footerContainer{
    background-color: #666;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0.3rem;
    width: 100%;
}

.footerText{
    font-family: 'Sparkasse_Rg';
    font-size: 12px;
    color: #fff;
    text-align: center;
    margin: 1px;
}
.footerMail{
    color: white;
}
.footerMail:hover{
    color: white;
    text-decoration: underline;
}

.footerPrivacy{
    color: white;
    margin-left: 3px;
}
.footerPrivacy:hover{
    color: white;
    text-decoration: underline;
}