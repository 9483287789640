.loginMainContainer {
  width: 100%;
  height: 100vh;
  background-color: #f0f0f0;
}

.loginMainContainer.modal {
  overflow: hidden;
}

.overlay {
  background-color: rgba(46, 46, 46, 0.7);
  height: 100vh; /*ex %*/
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.loginWrapLogo {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  min-height: 780px; /*ex 500*/
}
.loginWrapLogo.iphone6{
  min-height: 890px;
}

.login_Background_Logo_Container {
  display: flex;
  width: 100%;
  min-height: 96px;
  background-color: red;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
}

.LogoContainer {
  display: flex;
  height: 96px;
  align-items: center;
  padding-left: 1rem;
  cursor: pointer;
}

.login_Background_Logo_Container.modal {
  position: relative;
}

.loginCardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1rem;
  background: #f0f0f0;
}

.loginCard {
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  border-radius: 5px;
  width: 837px;
}

.loginCard.modal {
  z-index: 999;
}

.loginCardContainer.modal {
  margin-top: 0;
  background: none;
}

.loginInputCardContainer {
  display: flex;
  flex-direction: column;
  padding: 2rem 8rem;
  height: 100%;
}

.login_Background_Logo_item {
  display: flex;
  height: 100%;
  width: 0rem;
}

.CardTitle {
  margin-bottom: 2rem;
  color: #666;
  font-family: "Sparkasse_Bd";
  font-size: 20px;
  letter-spacing: 0.32px;
  line-height: 29px;
}

.login_passwordControll_container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  margin-bottom: 2rem;

  color: #666666;
  font-family: "Sparkasse_Rg";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 23px;
}

.link_NewPass {
  margin: 1rem 0rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Sparkasse_Bd;
}

/* SECOND STEP */

.inputExternalContainerLogin2 {
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.inputBoxLogin2 {
  width: 100%;
  height: 100%;
  outline: none;
  z-index: 0;
  text-align: center;
}

.newCodePhoneRaw {
  height: 2rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: flex;
}
.loginImgContainer {
  margin-top: 2rem;
  margin-bottom: 2rem;
  height: 10rem;
  width: auto;
  max-height: 100%;
}
.loginButtonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginButtonItem {
  width: 60%;
}

p {
  font-weight: 500;
  text-align: left;
  font-family: Sparkasse_Rg;
}
.Log_TC_Body{
  width: 100%;
}
.Log_TC_iFrame{
  width: 100%;
  border: none;
  height: 16rem;
}
.goBackIcon{
  width: 100%;
  display: flex;
  font-size: 21px;
  padding-left: 1.5rem;
  padding-top: 1.5rem;
}


.Footer {
  background-color: red;
  width: 100%;
  padding: 0.2rem 0;
}
.Footer.login {
  background-color: #5c5c5c;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 930px) {
  .loginCard {
    width: 100%;
  }
}

@media screen and (max-width: 815px) {
  .link_NewPass {
    flex-direction: column;
    gap: 6px;
  }
}

@media screen and (max-width: 767px) {
  .loginButtonItem {
    width: 100%;
  }
  .loginButtonContainer {
    padding: 0;
  }
  .loginInputCardContainer {
    padding: 2rem;
    min-width: auto;
  }
  .loginCard {
    height: auto;
  }
  .login_Background_Logo_Container {
    min-height: 75px;
  }
  .LogoContainer {
    display: flex;
    height: 75px;
  }
}
