.ModalButton{
    align-items: center;
    display: flex;
    justify-content: center;
    border: none;
    color: red;
    background-color: white;
    font-size: 22px;
    /* font-weight: 600; */
    cursor: pointer;
    margin-bottom: 0.3rem;
    font-family: Sparkasse_Bd; /*Sparkasse_Rg*/
}

@media screen and (max-width: 501px){
    .ModalButton{
        width: 100%;
    }
    /* .ant-modal-centered
    .ant-modal {
        max-width: calc(100vw- 40px)!important;
    } */
}