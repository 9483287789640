.PN_Princ_Container{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.PN_Header{
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 700;    
    padding:1rem; 
    color: red;
    background-color: white;
}
.PN_Body{
    width: 100%;
    height: 100%;
    margin-top: 0.5rem;
    padding: 1.5rem;
    background-color: white;
}
.PN_secondondarySpinner_Continer{
    width: 100%;
    height: 100%;
    padding: 0rem;
}
.PN_iFrame{
    width: 100%;
    height: 100%;
    border: none;
}