.changePin_Princ_Container{
    width: 100%;
    min-height:calc(100vh - 13rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid rgb(241, 238, 238); 
    padding: 0;
}
/*  HEADER  */
.changePin_headerRow{
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content:space-between;
    align-items: center;
}
.changePin_headerRow_Title{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-weight: 700;  
    background-color:white ;
    margin: .5rem 0;
    padding:1rem ;
    color: red;
    font-size: 22px;
    letter-spacing: 0.32px;
    line-height: 29px;
}
.changePin_headerRow_History{
    padding-top: .5rem;
    font-size: 1rem;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: rgba(123, 129, 134, 0.651);
    background-color: white;
    color: #666666;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    padding: 5px;
    padding-right: 1rem;
}
.changePin_Navigability{
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 0.4rem ;
    text-decoration: none;
}
.changePin_Navigability_right{
    font-weight:700; 
    margin-left:0.4rem;
    color:'gray'
}

.changePin_Navigability:hover{
    border-bottom: 1px solid gray;
}
/*  CARD  */
.changePin_Card{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 4rem;
    background-color: white;
}
.changePin_Card_ItemContainer{
    display: flex;
    flex-direction: column;
}
.changePin_Card_ButtonContainer{
    margin-top: 3rem;
    width: 25rem;
    align-self: center;
}
.changePin_Card_Success_TitleRow{
    width: 100%;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
    font-weight: 800;
}
.changePin_Card_Success_SubTitleRow{
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 3rem;
}

@media screen and (max-width: 767px)  {
    .changePin_Card_ButtonContainer{
        width: 100%;
        
    }
    .changePin_Card_ItemContainer{
        padding:2rem;
    }
    .changePin_Card{
        padding:0
    }
    .changePin_headerRow_History {
        align-items: center;
        width: 100%;
    }
    .changePin_Princ_Container{
        padding: 0 0.5rem
    }
    .changePin_Navigability,
    .changePin_Navigability_right{
        line-height: 1;
        text-align: left;
    }
}
