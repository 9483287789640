.statements_secondondarySpinner_Continer{
  width: 100%;
  padding: 0 30rem;
}

.statements_Page_Container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-right: 0.5rem ;
}
/*  HEADER  */
.statements_Header_Container {
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;
  background-color: white;
  padding: 1rem 1.5rem;
  color: red;
}
/* .statements_Select_Box{
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:2rem;
    border-bottom: 1px solid gray;
} */
.statements_Select_container {
  width: 100%;
  max-width: 30rem;
  height: 2.5rem;
  border: 1.5px solid rgb(175, 175, 175);
  border-radius: 5px;
  background-color: rgba(240, 236, 236, 0.699);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  cursor: pointer;
}
.statements_Select_item {
  width: 100%;
  height: 100%;
  font-size: large;
}
.anticon-calendar {
  color: red !important;
}

/*  BODY    */
.statements_Body_Container {
  width: 100%;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  /* padding-top: 1rem; */
}
.statements_Body_Container::-webkit-scrollbar {
  display: none;
}
.statements_AfterCall_Spinner{
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #6161619c;
}
@media screen and (min-width: 1401px) and (max-width: 1600px)  {
  .statements_secondondarySpinner_Continer{
      padding: 0 20rem;
    }
}
@media screen and (min-width: 1221px) and (max-width: 1400px)  {
  .statements_secondondarySpinner_Continer{
      padding: 0 15rem;
    }
}
@media screen and (min-width: 1001px) and (max-width: 1220px)  {
  .statements_secondondarySpinner_Continer{
      padding: 0 10rem;
    }
}
@media screen and (max-width: 1000px)  {
  .statements_secondondarySpinner_Continer{
      padding: 0 5rem;
    }
}
@media screen and (max-width: 767px) {
  .statements_Page_Container {
    padding: 0;
  }
  .statements_secondondarySpinner_Continer{
    padding: 2rem;
  }
  
}
@media screen and (max-width: 766px){
  .statements_secondondarySpinner_Continer{
      padding: 0;
    }
}
