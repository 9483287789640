.sidebarContainer{
    display:flex;
    width: 20rem;
    flex-direction: column;
    margin-top: 5rem;
    background-color: white;
    height: fit-content;
}
.singleCardContainerSidebar{
    width: 100%;
    height: 4rem;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: white;
}
.singleCardContainerSidebar.selected{
    background-color: white;
}
.singleCardItemSidebar{
    width: 100%;
    height: 90%;
    margin: 0;
    padding: 1rem;
    /* padding-left: 1.5rem; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.sidebarLabel{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; 
}

.menuIcon{
    margin-right: 1rem;
}

/*  ------ Option ------- */
.sidebar_labelSelect{
    color: #FF0000;
    font-family: Sparkasse_Bd;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 23px;
    font-weight: 600;
}
.sidebar_labelDeSelect{
    color: #666666;
    font-family: Sparkasse_Rg;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 23px;
}

.sidebar_rightCircleIconSelect{
    color: red;
    font-size: 1.5rem;
}
.sidebar_rightCircleIconDeSelect{
    color: gray;
    font-size: 1.5rem;
}