.App {
  text-align: center;
  min-width: 100%;
  min-height: 100%;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: red;
  border-color: red;
}

.ant-checkbox + span{
  padding-right: 0 !important;
  font-family: 'Sparkasse_Rg' !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner , 
.ant-checkbox-input:focus + .ant-checkbox-inner{
   border: 1px solid red !important;
}


.ant-modal-content,
.ant-modal-header{
  /* border-radius: 8pt !important; */
  border-bottom: 0;
}

.ant-modal-footer{
  border-top: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.ant-modal-body{
  font-family: Sparkasse_Rg !important;
}
.ant-modal-title{
  font-family: Sparkasse_Bd !important; /*Sparkasse_Rg*/
  font-weight: 600;
}

@media screen and (max-width: 500px){
  .ant-modal-footer{
    justify-content: center;
    gap:2px;
    width: 100%;
  }
  .ant-modal-content,
  .ant-modal-header,
  .ant-modal-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

}



@font-face {
  font-family: 'Sparkasse_Bd';
  src: local('Sparkasse_Bd'), url(./asset/font/Sparkasse_web_V2.04/Sparkasse_Bd.ttf) format('opentype');
}

@font-face {
  font-family: 'Sparkasse_Rg';
  src: local('Sparkasse_Rg'), url(./asset/font/Sparkasse_web_V2.04/Sparkasse_Rg.ttf) format('opentype');
}

@font-face {
  font-family: 'Sparkasse_BdIt';
  src: local('Sparkasse_BdIt'), url(./asset/font/Sparkasse_web_V2.04/Sparkasse_BdIt.ttf) format('opentype');
}

@font-face {
  font-family: 'Sparkasse_BdIt';
  src: local('Sparkasse_BdIt'), url(./asset/font/Sparkasse_web_V2.04/Sparkasse_BdIt.ttf) format('opentype');
}

@font-face {
  font-family: 'Sparkasse_It';
  src: local('Sparkasse_It'), url(./asset/font/Sparkasse_web_V2.04/Sparkasse_It.ttf) format('opentype');
}

@font-face {
  font-family: 'Sparkasse_Lt';
  src: local('Sparkasse_Lt'), url(./asset/font/Sparkasse_web_V2.04/Sparkasse_Lt.ttf) format('opentype');
}

@font-face {
  font-family: 'Sparkasse_Md';
  src: local('Sparkasse_Md'), url(./asset/font/Sparkasse_web_V2.04/Sparkasse_Md.ttf) format('opentype');
}

@font-face {
  font-family: 'SparkasseHead_Rg';
  src: local('SparkasseHead_Rg'), url(./asset/font/Sparkasse_web_V2.04/SparkasseHead_Rg.ttf) format('opentype');
}

@font-face {
  font-family: 'SparkasseSerif_Rg';
  src: local('SparkasseSerif_Rg'), url(./asset/font/Sparkasse_web_V2.04/SparkasseSerif_Rg.ttf) format('opentype');
}
@font-face {
  font-family: 'RobotoRegular';
  src: local('RobotoRegular'), url(./asset/font/Sparkasse_web_V2.04/roboto_regular.ttf) format('opentype');
}