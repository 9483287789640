.overlayBiometry{
    height: 100%;
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: #6161619c;
    z-index: 999;
}

.loaderBiometryContainer{
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #6161619c!important;
}

.biometryCardContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    margin-top: 0;
    background: none;
}

.biometryCard{
    display: flex;
    flex-direction: column;
    background-color: white;
    justify-content: center;
    border-radius: 5px;
    width: 837px;
}

.cardContainer{
    display: flex;
    flex-direction: column;
    padding: 2rem 8rem;
    height: 100%;
    justify-content: space-around;
  }


.popBiometry{
    min-height: 25rem ;
    min-width: 30rem ;
    max-height: 44rem ;
    max-width: 50rem ;
    background-color: #FFFFFF;
    align-items: center;
    display:flex;
    flex-direction: column;
    padding: 1.5rem 4rem 3rem 4rem;
    justify-content: space-around;
   
}

.iconsContainer{
  display: flex;
  justify-content: space-between;
}

.backIcon{
  display:flex;
  justify-content: flex-end;
  cursor: pointer;
  font-size: 21px;
  padding-left: 1.5rem;
  padding-top: 1.5rem;
}

.closeIcon{
    display:flex;
    justify-content: flex-end;
    cursor: pointer;
    font-size: 21px;
    padding-right: 1.5rem;
    padding-top: 1.5rem;
}

.popLink{
    color: #666666;
    text-decoration: underline;
    font-size: 0.875rem;
}

.popLink:hover{
   cursor: pointer;
}

.popTitle{
    color: #444444;
    font-size: 1.375rem;
    font-weight: bold;
    display:flex;
    justify-content: flex-start;
    width: 100%;
}

.smsDescription{
    color: #444444;
    font-size: 16px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 5rem;
    text-align: left;
}

.mfaDescription{
  color: #444444;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
}

.imgContainer{
    display: flex;
    width: 100%;
    max-width: 30rem ;
}

.containerConfirm{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0rem 10rem;
    margin-top: 2rem;
}

@media screen and (max-width: 930px) {
    .biometryCard {
      width: 100%;
    }
  }

 @media screen and (max-width: 767px)  {
  .cardContainer{
    padding:2rem;
    min-width: auto;
  }
  .buttonItem{
    width: 100%;
  }
  .containerConfirm{
    padding: 0;
  }
  .biometryCard {
    height: auto;
  }
 }
 @media screen and (max-width: 400px)  {

  .imgContainer{
      max-height: 9rem;
  }

 }