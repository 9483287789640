.collapsable{
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #D9D9D9;
    background-color: white;
    margin:0 1rem;
    padding: 5px 0;
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.collapsable.Mob{
    border:none
}
.collapsable.Last{
    border-bottom: none;
}

.collapsable-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 20px;
    align-items: center;
    font-size: large;
    cursor: pointer;
    
}
.collapsable-header.Mob{
    margin: 0;
    padding: 10px 20px;
    border-top:  1px  solid #D9D9D9;
    border-bottom:  1px  solid #D9D9D9;
}

.collapsable-header_leftContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 1rem;
}

.collapsable-header_icon-leftContainer,
.collapsable-header_rightContainer{
display: flex;
flex-direction: row;
align-items: center;
}

.collapsable-header_rightContainer_amountAndDescription{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 1rem;
    color: #444444;
}

.collapsable-header_leftContainer_description, 
.collapsable-header_rightContainer_amount{
    font-weight:700;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    color: #444444;
    font-family: 'Sparkasse_Rg';
    font-size: 16px;
    letter-spacing: 0;
    line-height: 23px;
}

.collapsable-header_rightContainer_amountAndDescription.mob,
.collapsable-header_rightContainer_amount.mob{
    margin-right: 0;
}
.collapsable-header_rightContainer_amount.positive{
    color: rgb(0, 184, 138) !important;
}

.collapsable-header_rightContainer_amountAndDescription.positive{
    color: rgb(0, 184, 138) !important;
}
.collapsable-header_rightContainer_amount.notCleared{
    color: #999999 !important;
}

.collapsable-header_leftContainer_authDateTime,
.collapsable-header_rightContainer_amountAndDescription.notCleared{
    color: #999999;
    font-family: 'Sparkasse_Rg';
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    font-weight: 600;
}
.collapsable_date_Container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.3rem;
    font-weight: 700;
    padding-top: 0.2rem;
    background-color: white;
    padding-left: 1.5rem;
    
}
.collapsable-details{
    display: flex;
    flex-direction:column; 
    padding: 10px 4.5rem;
    border-top: 1px solid #D9D9D9;
    animation-name: expand;
    animation-duration:  0.3s;
    overflow: hidden;
}
.collapsable-details.Mob{
    padding-top: 0 ;
    animation:none;
    border:none
}

@keyframes expand {
    0% {height: 0px; padding-bottom: 0; padding-top: 0}
    90% {height: 100%}
  }

  .collapsable-details_singleItem{
    display: flex;
    justify-content: space-between;
    align-content: flex-start;

    margin-bottom: .4rem;
  }
  .collapsable-details_singleItem.Mob{
    flex-direction: column;
    border-bottom: 1px solid #D9D9D9;
    height: 4rem;
    justify-content: center;
  }

.collapsable-details_lable,
.collapsable-details_info{
    width: 100%;
    text-align: left; 

    color: #999999;
    font-family: 'Sparkasse_Rg';
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
}

.collapsable-details_lables{
    margin-right: 5rem;
    color: #999999;
    font-weight:600
}

.collapsable-details_info{
    color: #444444;
    font-family: 'Sparkasse_Rg';
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
}

@media screen and (max-width: 767px)  {
    .collapsable{
        margin:0;
        border-left: none;
        border-right: none;
        border-top: none;
    }
    .collapsable-header_leftContainer_description, 
    .collapsable-header_rightContainer_amount{
        font-size: 14px;
    }
    .collapsable-header_leftContainer_authDateTime, 
    .collapsable-header_rightContainer_amountAndDescription.notCleared{
        font-size: 13px;
    }
    .collapsable-details_lables, 
    .collapsable-details_info{
        font-size: 12px;
    }
}
@media screen and (max-width: 600px)  {
    .collapsable-details{
        padding: 10px 1.5rem;
    }
}
