.FAQCollapsable_Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}
.FAQCollapsable_SingleBox_Container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 0.6rem;
  padding: 1.7rem;
  cursor: pointer;
  background: white;
}

.FAQCollapsable_SingleBox_TitleRaw {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.1rem;
  font-weight: 600;
}
.FAQCollapsable_collapsDetails {
  width: 100%;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;
  animation-name: FAQexpand;
  animation-duration: 0.3s;
  padding: 1rem;
  background: #fafafa;
  font-family: Sparkasse_Rg;
}

@keyframes FAQexpand {
  0% {
    height: 0;
    padding: 0 1rem;
  }
  90% {
    height: 100%;
  }
}

@media screen and (max-width: 767px) {
  .FAQCollapsable_SingleBox_Container {
    margin-top: .5rem;
  }
}
