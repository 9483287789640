.transactions_Page_Container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.transaction_ClosePage_EmptyContainer p {
  text-align: center;
}
/*  HEADER  */
.transaction_Header_Container {
  background-color: white;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0 0 0.8rem;
  font-size: 1.4rem;
  font-weight: 700;
  color: red;
}

.transactionBreadcrumb {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  color: #999999;
  font-size: 13px;
  padding-top: 0.5rem;
}

.transactionBreadcrumb_details,
.transactionBreadcrumb_transactions,
.transactionBreadcrumb_arrow {
  padding-right: 0.5rem;
}
.transactionBreadcrumb_arrow {
  color: red;
}

.transactionBreadcrumb_details,
.transactionBreadcrumb_arrow {
  font-weight: 600;
}

/*  BODY  */
.transactionsLoaderContainer{
  display: flex;
  align-items: center;
  width: 100%;
}
.transactions_Body_Component_View {
  width: 100%;
  margin-bottom: 5rem;
}
.searchBoxContainer {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  border-bottom: 1px solid rgb(216, 216, 216);
  padding: 0 1rem;
}

.searchBoxFull {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  display: flex;
  height: 100%;
  margin-right: 1rem;
  background-color: rgba(240, 240, 240, 0.29);
  width: 100%;
  max-width: 488px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1rem;
  font-size: 1rem;
}

.transactions_Search_inputBox_Container {
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}
.transaction_Search_inputBox_item {
  margin-left: 1rem;
  background-color: rgba(240, 240, 240, 0.29);
  height: 100%;
  width: 100%;
  max-width: 488px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  border: none;
  outline: 0 
}

.transaction_Search_Button {
  background-color: red;
  width: 4rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  
}

.transactions-Container {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  overflow: auto;
  padding-top: 1rem;
  padding-bottom: .4rem;
  background-color: white;
}

.transaction-Container_Header {
  width: 100%;
  /* height: 3rem; */
  display: flex;
  align-items: flex-start;
  font-size: 18px;
  font-weight: 700;
  padding-left: 1rem;
}

@media screen and (max-width: 767px) {
  .transactions_Body_Component_View {
    padding: 0;
  }
}
