.changeEPin_Princ_Container{
    width: 100%;
    min-height:calc(100vh - 13rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid rgb(241, 238, 238); 
    padding: 0;
}
/*  HEADER  */
.changeEPin_headerRow{
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content:space-between;
    align-items: center;
}
.changeEPin_headerRow_Title{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-weight: 700;  
    background-color:white ;
    margin: .5rem 0;
    padding:1rem ;
    color: red;
    font-size: 22px;
    letter-spacing: 0.32px;
    line-height: 29px;
}
.changeEPin_headerRow_History{
    padding-top: .5rem;
    font-size: 1rem;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: rgba(123, 129, 134, 0.651);
    background-color: white;
    color: #666666;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    padding: 5px;
    padding-right: 1rem;
}
.changeEPin_Navigability{
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 0.4rem ;
    text-decoration: none;
}
.changeEPin_Navigability_right{
    font-weight:700; 
    margin-left:0.4rem;
    color:'gray'
}

.changeEPin_Navigability:hover{
    border-bottom: 1px solid gray;
}

/*  CARD  */
.changeEPin_Card{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 4rem;
    background-color: white;
}
.changeEPin_Card_ItemContainer{
    display: flex;
    flex-direction: column;
}
.changeEPin_Card_TitleRow{
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #444444;
}
.changeEPin_Card_InputRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2rem;
}
.changeEPin_Card_ButtonContainer{
    margin-top: 3rem;
    width: 25rem;
    align-self: center;
}
.changeEPin_AfterCall_Spinner{
    display: flex;
    align-items: center;
    position: fixed;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #6161619c;
    z-index: 999;
}

/*  SUCCESS VIEW    */

.changeEPin_Card_Success_TitleRow{
    width: 100%;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
    font-weight: 800;
}
.changeEPin_Card_Success_SubTitleRow{
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 3rem;
}

@media screen and (max-width: 767px)  {
    .changeEPin_Card_ButtonContainer{
        width: 100%;
        
    }
    .changeEPin_Card_ItemContainer{
        padding:2rem;
    }
    .changeEPin_Card{
        padding:0
    }
    .changeEPin_headerRow_History {
        align-items: center;
        width: 100%;
    }
    .changeEPin_Princ_Container{
        padding: 0 0.5rem
    }
    .changeEPin_Navigability,
    .changeEPin_Navigability_right{
        line-height: 1;
        text-align: left;
    }
}



