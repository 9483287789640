.langButtonContainer{
    display: flex;
    position: relative;
    flex-direction: column;
}

.langButton{
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 2px white;
    color: white;
    cursor: pointer;
    font-family: 'Sparkasse_Bd';
    background-color: inherit;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.langButton:hover{
    background-color:white;
    color:red
}

.langTooltip{
    position: absolute;
    background-color: #fff;
    font-family: 'Sparkasse_Lt';
    border: none;
    top:48px;
    right: 3px;
    cursor: pointer;
    box-shadow: 1px 2px rgba(0, 0, 0, 0.1);
}

.langTooltip:hover{
    text-decoration: underline;
}

.triangleToolTip {
	width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid white;
    position: absolute;
    top: 42px;
    right: 14px;
}
