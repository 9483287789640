.carouselContainer{
    width: 0rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
    overflow-x: hidden;
}
.buttonCarouselContainer{
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 1;

}
.carouselDirectionalButtonArrow{
    height: 100%;
    width: 2.5rem;
    z-index: 100;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.carouselDirectionalButtonArrow.Right{
    margin-left:.5rem;
}
.carouselDirectionalButtonArrow.Left{
    margin-right:.5rem;
    }
.buttonArrayMask{
    width: 100%;
    overflow-x: hidden;
}

.uiButtonCarousel_button{
    border: 2px solid red;
    border-radius: 1.5rem;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    font-weight: 700;
    margin: 0.5rem;
    height: 2.2rem;
    min-width: 10rem;
    font-size: 0.8rem;
    cursor: pointer;
}
.uiButtonCarousel_button.selected{
    background-color: red;
    color: white;
}

@media screen and (max-width: 767px) {
    /* .carouselContainer{
        overflow-x: auto;
        justify-content: flex-start;
        flex-wrap:nowrap;
    } */

}