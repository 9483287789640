.inputBoxContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2rem;
}

.inputIcons{
  margin-top: .4rem;
}

.titleStyle {
    color: #666666;
    font-family: Sparkasse_Rg;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 23px;
    text-align: start
}

.inputExternalContainer {
    width: 60%;
    height: 2.1rem;
    display: flex;
    position: relative;
    font-size: 1rem !important;
}
.flexigeldIdExternalContainer{
    width: 60%;
    height: 2.1rem;
}
.flexigeldIdinputExternalContainer {
    width: 100%;
    display: flex;
    position: relative;
    font-size: 1rem !important;
}

.flexigeldIdinputExternalContainer input::-webkit-credentials-auto-fill-button {
    position:absolute;
    right:45px;
}

.flexiGeldControllLabel{
    color: red;
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Sparkasse_Bd';
    padding-left: 3px;
}

.inputBox {
    width: 100%;
    height: 100%;
    outline: none;
    z-index: 0;

    font-family: Sparkasse_Rg;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 23px;
}

.inputBox.password{
    padding-right: 50px;
}

.visibilityContainer {
    font-size: 1.2rem;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    position: absolute;
    right: 1.1rem;
}
/*  ANTD    input box (input)   */
.ant-input {
    font-size: 16px !important;
}
/*  ANTD Input box 200    */
.ant-input-group-addon {
    background-color: white!important;
    border-right:none!important;
    font-size: 16px!important;
}
/*  ANTD Baloon triangle    */
.ant-tooltip-arrow-content {
    border:6px solid rgb(235, 234, 234)!important;
    background-color: rgb(235, 234, 234)!important;
    margin-bottom: -1.5px !important; 
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow-content, .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content, .ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
     box-shadow: none!important;
}


@media screen and (max-width: 650px)  {
    .inputBoxContainer{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 2rem;
        justify-content: center;
    }
    .inputExternalContainer {
        width: 100%;
    }
    .flexigeldIdExternalContainer{
        width: 100%;
    }
}
@media screen and (max-width: 335px)  {
    .visibilityContainer {
        right: .55rem;
    }
}

