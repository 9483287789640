.opacityLayer {
  background-color: rgba(46, 46, 46,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  min-height: 100vh;

  padding: 0.5rem ;
  overflow: auto;
}

.otpInputCardContainer{
  display: flex;
  flex-direction: column;
  padding: 2rem 8rem;
  height: 100%;
  justify-content: space-around;
}

.SMSpopCard{
    min-height: 25rem ;
    min-width: 30rem ;
    max-height: 44rem ;
    max-width: 50rem ;
    background-color: #FFFFFF;
    align-items: center;
    display:flex;
    flex-direction: column;
    padding: 1.5rem 4rem 3rem 4rem;
    justify-content: space-around;
    border-radius: 6px;
    z-index: 99;
   
}
.SMSpopDescription{
    color: #444444;
    font-size: 16px;
    display:flex;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 5rem ;
    text-align: left;
}
.SMScontainerOTP{
  width: 100%;
}
.SMSpopLink{
  color: #666666;
  text-decoration: underline;
  font-size: 0.875rem;
  padding-top: 4rem;
}
.SMSpopLink:hover{
  cursor: pointer;
}
.SMSpopButtonContainer{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 10rem;
  margin-top: 2rem ;
 }

 @media screen and (max-width: 767px)  {
  .otpInputCardContainer{
    padding:2rem;
    min-width: auto;
  }
  .buttonItem{
    width: 100%;
  }
  .SMSpopButtonContainer{
    padding: 0;
  }
 }