.triangle {
    display: flex;
    position: fixed;
    top: 3.6rem;
    right: 16.5rem;
	width: 0;
	height: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-bottom: 15px solid white;
}
.userCardView{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.userCard{
    display: flex;
    width: 100%;
    border: 1px solid, transparent;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.305);
    border-radius: 10px;
    background-color: white;
    justify-content: center;
    align-items: center;
}
.userCard.Mob{
    border-radius: 0%;
    box-shadow:none
}

.userCardContent{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.personalDataContainer{
width: 100%;
}

.userCardItem{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 1.3rem;
    justify-content: space-between;
}

.userCardItem.Mob{
    flex-direction: column;
}

.contactInfoContainer{
    width: 100%;
}

.contactInfoItem{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0 1.3rem;
}
.privacyNoticeContainer{
    width: 100%;
    border-top: 2px solid #F0F0F0;
    display: flex;
    justify-content: flex-start;
    align-items: center ;
    flex-direction: row;
}
.privacyNoticeItem{
    width: 27rem;
    height: 4rem;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 0.5rem;
    margin-left: 0.8rem;
    cursor: pointer;
    background: none;
    border: none;   
}
.privacyNoticeItem_label{
    font-size: 1.5rem;
    font-weight: 600;
    color: gray;
    cursor: pointer;
}
.privacyNoticeItem_circleIcon{
    font-size: 1.7rem;
    color: gray;

}
.userCardTitle{
    width: 100%;
    text-align: left;
    justify-content: center;
    align-items: center;
    background-color: #F0F0F0;
    font-size: 1.08rem;
    color: rgb(95, 95, 95);
    padding-left: 1.3rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    font-family: Sparkasse_Bd;
}

.userCardTitle.Mob{
    font-size: 1rem;
    color: darkgray;
    border-top-right-radius: 0  ;
    border-top-left-radius: 0 ;
}

.uiLabelUserContainer{
    height: 2rem;
    width: 100%;
    margin: 0.5rem;
    margin-left: 0;
    margin-right: 0;
}

.uiLabelUserContainerMob{
    margin: 0.5rem;
    margin-left: 0;
    margin-right: 0;
    border-bottom: 1px solid darkgray;
    word-break: break-word;
}

.uiLabelUserContainerMob.last{
    border-bottom: none;
    margin-top: 0;
}

@media screen and (min-width: 900px) and (max-width:1700px){
    .triangle{
        right: 16rem;
    }
}
@media screen and (min-width: 700px) and (max-width:800px){
    .triangle{
        right: 16rem;
    }
    .privacyNoticeItem{
        width: 33rem;
    }
}
@media screen and (min-width: 500px) and (max-width:699px){
    .triangle{
        right: 16rem;
    }
    .privacyNoticeItem{
        width: 29rem;
    }
    .privacyNoticeItem_label {
        font-size: 1.2rem;
    }
    .privacyNoticeItem_circleIcon{
        font-size: 1.1rem;
    }
}

@media screen and (max-width:500px){
    .privacyNoticeItem_label {
        font-size: 16px;
    }
    .privacyNoticeItem_circleIcon{
        font-size: 1.2rem;
    }
}

@media screen and (max-height:830px)and (min-width:768px){

    .userCardContent{
        overflow:scroll;
        height: 100vh;
        padding-bottom: 3.5rem;
    }

}
