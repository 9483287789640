.iframePinContainer,
.iframePinWindow{
    border: none;
    height:  100%;
    width: 100%;
}

@media screen and (max-height:700px){
    .iframePinWindow{
        height:  100vh;
    }
}