@keyframes expandSideBar {
    0%  {width: 0px;}
    100% {width: 100%;}
  }

.mobileSidebarContainer{
    max-width: 90%;
    width: 90%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #FF0000;
    position: absolute;
    z-index: 103;
    animation-name: expandSideBar;
    animation-duration:  0.2s;
}
.sidebarOverlay{
    /* height: calc(100% - 5rem); */
    min-width: 100%;
    background-color: rgba(128, 128, 128, 0.534);
    position: absolute;
    z-index: 102;
}
.singleCardContainerMobSidebar,.singleCardContainerSidebarBottom{
    width: 100%;
    height: 4rem;
    border-bottom: 1px solid white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
}
.singleCardContainerMobSidebar.selected{
    background-color: white;
}

.bottomContainer{
    display: flex;
    flex-direction: column;
    align-content: flex-end;
}

.singleCardItemSidebar{
    width: 100%;
    height: 90%;
    margin: 0;
    padding: 1rem;
    /* padding-left: 1.5rem; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sidebarLabel{
    display: flex;
    justify-content: center;
    align-items: center;
}
.menuIcon{
    margin-right: 1rem;
}
/*  ------ Option ------- */
.mobSidebar_labelSelect{
    color: red;
    font-weight: 700;
}
.mobSidebar_labelDeSelect{
    color: white;
    font-weight: 500;
}


.mobsidebar_rightCircleIconSelect{
    color: red;
    font-size: 1.3rem;
}
.mobsidebar_rightCircleIconDeSelect{
    color: white;
    font-size: 1.3rem;
}

@media screen and (max-height: 592px)  {
    /* .sidebarOverlay{
        height: 100vh ;
    } */
    .mobileSidebarContainer{
        justify-content:flex-start
    }
    .singleCardContainerMobSidebar,
    .singleCardContainerSidebarBottom{
    height: 3.5rem;
    }
}