.opacityLayer {
    background-color: rgba(46, 46, 46,0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100vw;
    min-height: 100vh;
  
    padding: 0.5rem ;
    overflow: auto;
}
.fmaInputCardContainer{
    display: flex;
    flex-direction: column;
    padding: 2rem 8rem;
    height: 100%;
    justify-content: space-around;
}
.MFApopCard{
    min-height: 25rem ;
    min-width: 30rem ;
    max-height: 44rem ;
    max-width: 50rem ;
    background-color: #FFFFFF;
    align-items: center;
    display:flex;
    flex-direction: column;
    padding: 1.5rem 4rem 3rem 4rem;
    justify-content: space-around;
    border-radius: 6px;
    z-index: 99;
}
.MFApopDescription{
    color: #444444;
    font-size: 16px;
    font-family:'Sparkasse_Bd' ;
    display:flex;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 5rem ;
    text-align: left;
}
.MFAImgContainer{
    display: flex;
    width: 100%!important;
    height: auto;
    max-width: 30rem ;
    padding-left:0;
    padding-right:0;
    
}
.MFApopLink{
    color: #666666;
    text-decoration: underline;
    font-size: 0.875rem;
  }
  .MFApopLink:hover{
    cursor: pointer;
  }

   @media screen and (max-width: 767px)  {
    .mfaInputCardContainer{
      padding:2rem;
      min-width: auto;
    }


   }
   @media screen and (max-width: 400px)  {

    .MFAImgContainer{
        max-height: 9rem;
    }

   }