.bodyContainer{
    display: flex;
    width: 100%;
    min-height: calc(100vh - 78px); /*prev -107*/
    padding: .5rem .5rem .5rem .5rem;
}

.homeScreenContainer{
background-color:#f0f0f0;; ;
}

.loaderContainer{
    display: flex;
    align-items: center;
}

.iconLauncher{
    width: 100vw;
    height: 8rem;
    position: absolute;
}
.spinner{
    padding: 8rem;
}
.loaderBiometryContainer{
    display: flex;
    align-items: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.342);
    height: 100vh;
}

.userCardContainer{
    position: fixed;
    right: 3rem;
    top:5rem;
    width: 42rem;
    display: flex;
    justify-content: center;
    align-items:flex-end;
    flex-direction: column;
    margin-top: -0.45rem;
    z-index: 102;
}

.userCardContainer.mob{
    width: 100%;
}

.homeScreen{
    display: flex;
    width: calc(100% - 20rem);
    flex-direction: column;
    margin: 5rem 0rem 0rem 0.5rem;
}

.homeScreen.siderOpen{
    overflow-y: hidden;
}

.homeScreenContainer.siderOpen{
    overflow-y: hidden;
}

@media screen and (max-width: 767px) {
    .homeScreen{
        margin-left: 0;
        margin-top: 0;
        overflow-y: auto;
        padding-top: .5rem;
        width: 100%;
    }
    .iconLauncher{
        height: 5rem;
    }
    .spinner{
        padding:3rem
    }   
    .bodyContainer{
        padding: 0;
    }
}





