.FAQ_Page_Container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  /* padding: 0 2rem; */
}
.faq_secondondarySpinner_Continer{
  width: 100%;
  padding: 0 30rem;
}

/*  HEADER  */
.FAQ_Header_Container {
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  color: red;
  background-color: white;
  padding: 1rem 1rem;
  margin-bottom: 15px;
}

.FAQ_Carosel_Box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  background-color: white;

  padding: 1rem 0; 
  overflow: hidden;
}

/*  BODY    */

.FAQ_Body_Container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  margin-bottom: 3rem;
}


@media screen and (min-width: 1401px) and (max-width: 1600px)  {
  .faq_secondondarySpinner_Continer{
      padding: 0 20rem;
    }
}
@media screen and (min-width: 1221px) and (max-width: 1400px)  {
  .faq_secondondarySpinner_Continer{
      padding: 0 15rem;
    }
}
@media screen and (min-width: 1001px) and (max-width: 1220px)  {
  .faq_secondondarySpinner_Continer{
      padding: 0 10rem;
    }
}
@media screen and (max-width: 1000px)  {
  .faq_secondondarySpinner_Continer{
      padding: 0 5rem;
    }
}
@media screen and (max-width: 767px) {
  .FAQ_Page_Container {
    padding: 0;
  }
  .faq_secondondarySpinner_Continer{
    padding: 2rem;
  }
}
@media screen and (max-width: 766px){
  .faq_secondondarySpinner_Continer{
      padding: 0;
    }
}