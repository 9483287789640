.privacyNotice_Container{
    text-align: start;
    padding: 2rem;
    margin-top: 5rem;
}

.privacyNotice_Background_Logo_Container {
    display: flex;
    width: 100%;
    height: 5rem;
    background-color: red;
    align-items: center;
    justify-content: space-between;
    padding-right: 1rem;
    position: fixed;
    top:0;
}

.privacyNotice_Background_Logo_Container .LogoContainer {
    display: flex;
    height: 5rem;
    align-items: center;
    padding-left: 1rem;
    cursor: pointer;
}

