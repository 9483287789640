.navbarContainer {
  width: 100%;
  position: fixed;
  top: 0;
  height: 5rem;
  display: flex;
  background-color: red;
  justify-content: space-between;
  padding-right: 1rem;
  align-items: center;
  z-index: 100;
}
.navbarLogoContainer {
  display: flex;
  height: 80px;
  align-items: center;
  padding-left: 1rem;
  cursor: pointer;
}
.navbarButtonContainer {
  display: flex;
}
.navbarUserContainer,
.navbarLogoutContainer {
  display: flex;
  height: 3rem;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
}

.navbarUserContainer {
  margin-right: 2rem;
}

.navbarLogoutContainer {
  margin-right: 1rem;
}

.navbarButtonText {
  padding: 1rem;
  padding-right: 0rem;

  color: #ffffff;
  font-family: Sparkasse_Rg;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 23px;
}
.ant-modal-close {
  display: none;
}

/* MOBILE */

@media screen and (max-width: 767px) {
  .navbarContainer {
    padding: 1rem;
    position: relative;
  }
}
@media screen and (max-width: 350px) {
  .navbarLogoContainer {
    width: 12rem;
  }
}
