.mobileBanner_Container{
    width: 100%;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #444444;
    position: fixed;
    top: 0;
    z-index: 9999;
}
.mobileBanner_Item{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.mobileBanner_ColseIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
}
.mobileBanner_IconAndLabel_Container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.mobileBanner_label{
    font-size: 1.2rem;
    color: white;
    font-weight: 500;
    margin-left: 0.5rem;
    font-family: Sparkasse_Rg;
}
.mobileBanner_Button_Container{
    height: 1.9rem;
    width: 5rem;
    border: none;
    border-radius: 3px;
    border: none;
    border-radius: 3px;
    font-weight: 500;
    color: rgb(253, 253, 253);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.mobileBanner_Button_Item{
    display: flex;
    height: 100%;
    width: 100%;
    font-size: 10px;
    border: none;
    border-radius: 3px;
    font-weight: 500;
    color: rgb(253, 253, 253);
    cursor: pointer;
    justify-content: center;
    align-items: center;
    background-color: red; 
}
.mobileBAnner_ButtonIcon_Container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0.4rem;
    
}
.mobileBanner_buttonIcon{
    height: 1rem;
    width: 1rem;
}