.TAC_Princ_Container{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.TAC_Header{
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 700;    
    padding:1rem; 
    color: red;
    background-color: white;
}
.TAC_Body{
    width: 100%;
    height: 100%;
    margin-top: 0.5rem;
    padding: 1.5rem;
    background-color: white;
}
.TC_secondondarySpinner_Continer{
    width: 100%;
    height: 100%;
    padding: 0rem;
  }
  .TC_iFrame{
      width: 100%;
      height: 100%;
      border: none;
  }