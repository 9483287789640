.buttonContainer{
    height: 2.25rem;
    width: 100%;
    border: none;
    border-radius: 3px;
    border: none;
    border-radius: 3px;
    font-weight: 500;
    color: rgb(253, 253, 253);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonIcon{
    margin-left: 1rem;
}
 
.buttonItem {
    display: flex;
    width: 100%;
    height: 100%;
    font-size: 16px;
    border: none;
    border-radius: 3px;
    color: rgb(253, 253, 253);
    cursor: pointer;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    justify-content: center;
    align-items: center;
    font-family: Sparkasse_Bd; /*Sparkasse_Bd*/
}


@media screen and (max-width:450px){
    .buttonContainer {
        height: 1.9rem;
    }
}
