.carouselContainer{
    width: 0rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
    overflow-x: hidden;
}
.promoCarouselContainer{
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 1;
}
.carouselDirectionalButtonArrowPromo{
    height: 100%;
    width: 2.5rem;
    z-index: 100;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
}
.carouselDirectionalButtonArrowPromo.Right{
    margin-left:.5rem;
    right: 0;
    
}
.carouselDirectionalButtonArrowPromo.Left{
    margin-right:.5rem;
    left: 0;
    }

#backButtonCarouselPromo{
    left:0px;
}

#nextButtonCarouselPromo{
    right:0px;
}


.buttonArrayMaskPromo{
    width: 100%;
    overflow-x: hidden;
}

.uiPromoCarousel_container{
    border:1px solid lightgrey;
    background-color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin: 0.5rem;
    height: 11rem;
    width: 11rem;
    min-width: 11rem;
    font-size: 0.9rem;
    position: relative;
    border-radius: 0.9rem;
    z-index: 0;
    cursor: pointer;
}
.uiPromoCarousel_container.selected{
    background-color: red;
    color: white;
}

.uiPromoCarousel_container .uiPromoCarousel_title{
    background-color: white;
    width: 100%;
    border-radius:0.6rem;
    border:1px solid gray;
    position: absolute;
    bottom: 0px;
    min-height: 3rem;
}

.promotionArea{
    height: calc(100% - 3rem);
    width: 100%;
    position: absolute;
    bottom:0px;
}

.promotionTourCarousel {
    padding: 1rem;
    margin-top: 0.5rem;
    padding-right: 2rem;
    padding-left: 2rem;
    background-color: white;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
}

@media screen and (max-width: 767px) {
    .buttonArrayMaskPromo{
        width: 100%;
        overflow-x: scroll;
    }

    #nextButtonCarouselPromo{
        display: none;
    }

    #backButtonCarouselPromo{
        display: none;
    }
}