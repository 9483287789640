.promoImage{
    width: 100%;
    height: 100%;
    border-radius: 0.9rem;
    object-fit: cover;
}

.closeOffButton{
    position: absolute;
    top: 0.35rem;
    right: 0.35rem;
    z-index:10;
    cursor: pointer;
}

.promoImage.hidden{
    display: none;
}

.uiPromoCarousel_container.loading {
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
    background-image: url('../../../../asset/svg/promoLoader.svg');
}

.uiPromoCarousel_container.error.DE{
    background-image: url('../../../../asset/img/Promo/errorPromoDE.svg');
}

.uiPromoCarousel_container.error.EN{
    background-image: url('../../../../asset/img/Promo/errorPromoEN.svg');
}

.uiPromoCarousel_container.error {
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
}